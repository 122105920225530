
import React from 'react';
import './style.css'
import Primavera from '../assets/primavera.png'
import headphone from '../assets/headphones.png'
import full from '../assets/full2.gif'
import full0 from '../assets/full.gif'

import victory from '../assets/victory.mp3'
import ampyx from '../assets/ampyx.mp3'

class PlayGame extends React.Component {

    audio = new Audio("https://bingogamesoound.s3-sa-east-1.amazonaws.com/victory.mp3");
    audioFull = new Audio("https://bingogamesoound.s3-sa-east-1.amazonaws.com/ampyx.mp3");

    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered

        var storage = localStorage.getItem('playlist');
        if (storage) {
            var item = JSON.parse(storage)
            var songs = [];
            songs.push(
                { "id": 0, "name": item.song1, "author": item.artist1 },
                { "id": 1, "name": item.song2, "author": item.artist2 },
                { "id": 2, "name": item.song3, "author": item.artist3 },
                { "id": 3, "name": item.song4, "author": item.artist4 },
                { "id": 4, "name": item.song5, "author": item.artist5 },
                { "id": 5, "name": item.song6, "author": item.artist6 },
                { "id": 6, "name": item.song7, "author": item.artist7 },
                { "id": 7, "name": item.song8, "author": item.artist8 },
                { "id": 8, "name": item.song9, "author": item.artist9 },
                { "id": 9, "name": item.song10, "author": item.artist10 }

            );
            this.state = {
                item: item,
                songs: songs,
                playAciert: false,
                select: []
            };
        }
        this.select = this.select.bind(this);;
    }

    stopAnimation = () => {
        this.setState({ playAciert: false, playAciertAll: false });
        clearInterval(this.intervalId);
    }

    checkOkey = () => {
        const audioPromise = this.audio.play()
        if (audioPromise !== undefined) {
            audioPromise
                .then(_ => {
                    // autoplay started
                })
                .catch(err => {
                    // catch dom exception
                    console.info(err)
                })
        }
        this.setState({ playAciert: true });
        this.intervalId = setInterval(this.stopAnimation.bind(this), 7000);
    }


    checkOkeyAll = () => {
        const audioPromise = this.audioFull.play()
        if (audioPromise !== undefined) {
            audioPromise
                .then(_ => {
                    // autoplay started
                })
                .catch(err => {
                    // catch dom exception
                    console.info(err)
                })
        }
        this.setState({ playAciertAll: true });
        this.intervalId = setInterval(this.stopAnimation.bind(this), 17000);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.audio.load()
        this.audioFull.load()

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    select(item) {
        var selects = this.state.select
        var bandera = false
        window.scrollTo(0, 0)

        if (selects.length === 0) {
            this.checkOkey();
            this.setState({ select: [item.id] })
        } else {
            selects.map((i) => {
                if (i == item.id) {
                    bandera = true;
                }
            })
            var remove = false;
            if (bandera) {
                const sta = selects.filter(s => s !== item.id);
                remove = true;
                this.setState({ select: sta })
            } else {
                selects.push(item.id)
                this.setState({ select: selects })
            }
            if (!remove) {
                if (selects.length === 7) {
                    this.checkOkeyAll();
                } else {
                    var sta = selects.filter(s => s == item.id);
                    if (sta.length === 1) {
                        this.checkOkey();
                    }
                }

            }

        }
    }
    colorImage(id) {
        const celeste = 'rgb(88,122,142)'
        var select = this.state.select
        var bandera = false
        select.map((item) => {
            if (id == item) {
                bandera = true;
            }
        })
        if (bandera) {
            return '#e4431d'
        } else {
            return 'white'
        }
    }

    render() {
        const oscuro = 'rgba(25,25,25,0.6)'
        var card1 = this.colorImage(0) === 'white' ? "cardSong" : "cardSongSelect";
        var card2 = this.colorImage(1) === 'white' ? "cardSong" : "cardSongSelect";
        var card3 = this.colorImage(2) === 'white' ? "cardSong" : "cardSongSelect";
        var card4 = this.colorImage(3) === 'white' ? "cardSong" : "cardSongSelect";
        var card5 = this.colorImage(4) === 'white' ? "cardSong" : "cardSongSelect";
        var card6 = this.colorImage(5) === 'white' ? "cardSong" : "cardSongSelect";
        var card7 = this.colorImage(6) === 'white' ? "cardSong" : "cardSongSelect";
        var card8 = this.colorImage(7) === 'white' ? "cardSong" : "cardSongSelect";
        var card9 = this.colorImage(8) === 'white' ? "cardSong" : "cardSongSelect";
        var card10 = this.colorImage(9) === 'white' ? "cardSong" : "cardSongSelect";

        var colorText1 = this.colorImage(0) === 'white' ? "#310a46" : "white";
        var colorText2 = this.colorImage(1) === 'white' ? "#310a46" : "white";
        var colorText3 = this.colorImage(2) === 'white' ? "#310a46" : "white";
        var colorText4 = this.colorImage(3) === 'white' ? "#310a46" : "white";
        var colorText5 = this.colorImage(4) === 'white' ? "#310a46" : "white";
        var colorText6 = this.colorImage(5) === 'white' ? "#310a46" : "white";
        var colorText7 = this.colorImage(6) === 'white' ? "#310a46" : "white";
        var colorText8 = this.colorImage(7) === 'white' ? "#310a46" : "white";
        var colorText9 = this.colorImage(8) === 'white' ? "#310a46" : "white";
        var colorText10 = this.colorImage(9) === 'white' ? "#310a46" : "white";


        const h = window.innerHeight;
        if (false && window.innerWidth >= 850) {

            return (
                <div className='bodycR' style={{ height: h }}>
                    {this.state.playAciert ?
                        <div style={{ flexDirection: 'column', flex: 1, position: 'absolute', top: '25%', left: 0, bottom: 0, right: 0, textAlign: 'center', verticalAlign: 'middle', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={full} style={{ position: 'aboslute', top: 0, left: 0, backgroundColor: 'transparent', textAlign: 'center' }} />
                        </div>
                        : <div />
                    }
                    {this.state.playAciertAll ?
                        <div>
                            <div style={{ flexDirection: 'column', flex: 1, position: 'absolute', top: '25%', left: 0, bottom: 0, right: 0, textAlign: 'center', verticalAlign: 'middle', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={full} style={{ position: 'aboslute', top: 0, left: 0, backgroundColor: 'transparent', textAlign: 'center' }} />
                            </div>
                            <div style={{ flexDirection: 'column', flex: 1, position: 'absolute', top: '25%', left: 0, bottom: 0, right: 0, textAlign: 'center', verticalAlign: 'middle', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={full0} style={{ position: 'aboslute', top: 0, left: 0, backgroundColor: 'transparent', textAlign: 'center' }} />
                            </div>
                        </div>
                        : <div />
                    }

                    <div className='simple_form' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='card' style={{ width: '80%', height: '85%', display: 'flex', flexDirection: 'column' }}>
                            <div className='cardHeader' style={{ width: '100%', height: '15%', display: 'flex', alignItems: 'center' }}>
                                <p style={{ fontSize: 66, letterSpacing: 5, fontFamily: 'URWGeometricBold', textShadow: '8px 8px 6px #000000', color: 'white', marginLeft: 10 }}>TU  PLAYLIST</p>
                            </div>
                            <div className='cardFooter' style={{ width: '100%', height: '85%', backgroundImage: 'linear-gradient(to right, #171029 0%, #58336a)' }}>
                                <div className='cardImg' style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ width: '90%', height: '90%', flexDirection: 'row' }}>
                                        <div style={{ height: '33%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                            <div onClick={() => this.select(this.state.songs[0])} className={card1} style={{ margin: 10, backgroundColor: this.colorImage(0) }}>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText1, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[0].name}</p>
                                                </div>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText1, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[0].author}</p>
                                                </div>
                                            </div>
                                            <div onClick={() => this.select(this.state.songs[1])} className={card2} style={{ margin: 10, backgroundColor: this.colorImage(1) }}>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText2, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[1].name}</p>
                                                </div>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText2, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[1].author}</p>
                                                </div>
                                            </div>
                                            <div onClick={() => this.select(this.state.songs[2])} className={card3} style={{ margin: 10, backgroundColor: this.colorImage(2) }}>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText3, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[2].name}</p>
                                                </div>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText3, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[2].author}</p>
                                                </div>
                                            </div>
                                            <div onClick={() => this.select(this.state.songs[3])} className={card4} style={{ margin: 10, backgroundColor: this.colorImage(3) }}>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText4, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[3].name}</p>
                                                </div>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText4, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[3].author}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ height: '33%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                                            <div onClick={() => this.select(this.state.songs[4])} className={card5} style={{ margin: 10, backgroundColor: this.colorImage(4) }}>
                                                <div style={{ height: '60%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText5, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[4].name}</p>
                                                </div>
                                                <div style={{ height: '60%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText5, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[4].author}</p>
                                                </div>
                                            </div>
                                            <div onClick={() => this.select(this.state.songs[5])} className={card6} style={{ margin: 10, backgroundColor: this.colorImage(5) }}>
                                                <div style={{ height: '60%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText6, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[5].name}</p>
                                                </div>
                                                <div style={{ height: '60%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText6, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[5].author}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ height: '33%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                            <div onClick={() => this.select(this.state.songs[6])} className={card7} style={{ margin: 10, backgroundColor: this.colorImage(6) }}>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText7, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[6].name}</p>
                                                </div>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText7, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[6].author}</p>
                                                </div>
                                            </div>
                                            <div onClick={() => this.select(this.state.songs[7])} className={card8} style={{ margin: 10, backgroundColor: this.colorImage(7) }}>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText8, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[7].name}</p>
                                                </div>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText8, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[7].author}</p>
                                                </div>
                                            </div>
                                            <div onClick={() => this.select(this.state.songs[8])} className={card9} style={{ margin: 10, backgroundColor: this.colorImage(8) }}>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText9, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[8].name}</p>
                                                </div>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText9, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[8].author}</p>
                                                </div>
                                            </div>
                                            <div onClick={() => this.select(this.state.songs[9])} className={card10} style={{ margin: 10, backgroundColor: this.colorImage(9) }}>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                    <p style={{ color: colorText10, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[9].name}</p>
                                                </div>
                                                <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                    <p style={{ color: colorText10, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[9].author}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div style={{ width: '80%', height: '5%', display: 'flex', marginTop: 10, justifyContent: 'flex-end' }}>
                            <p style={{ color: 'white', fontSize: 16, fontFamily: 'URWGeometricMedium', margin: 0, letterSpacing: 1.5 }}>#{this.state.item.ticket}</p>
                        </div>

                    </div>
                </div>
            )

        } else {

            card1 = card1 + "Responsive";
            card2 = card2 + "Responsive";
            card3 = card3 + "Responsive";
            card4 = card4 + "Responsive";
            card5 = card5 + "Responsive";
            card6 = card6 + "Responsive";
            card7 = card7 + "Responsive";
            card8 = card8 + "Responsive";
            card9 = card9 + "Responsive";
            card10 = card10 + "Responsive";

            return (
                <div className='bodycR' style={{ height: h }}>
                    {this.state.playAciert ?
                        <div style={{ flexDirection: 'column', flex: 1, position: 'absolute', top: '25%', left: 0, bottom: 0, right: 0, textAlign: 'center', verticalAlign: 'middle', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={full} style={{ position: 'aboslute', top: 0, left: 0, backgroundColor: 'transparent', textAlign: 'center' }} />
                        </div>
                        : <div />
                    }
                    {this.state.playAciertAll ?
                        <div>
                            <div style={{ flexDirection: 'column', flex: 1, position: 'absolute', top: '25%', left: 0, bottom: 0, right: 0, textAlign: 'center', verticalAlign: 'middle', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={full} style={{ position: 'aboslute', top: 0, left: 0, backgroundColor: 'transparent', textAlign: 'center' }} />
                            </div>
                            <div style={{ flexDirection: 'column', flex: 1, position: 'absolute', top: '25%', left: 0, bottom: 0, right: 0, textAlign: 'center', verticalAlign: 'middle', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={full0} style={{ position: 'aboslute', top: 0, left: 0, backgroundColor: 'transparent', textAlign: 'center' }} />
                            </div>
                        </div>
                        : <div />
                    }
                    <div className='bodycResponsiveGame' style={{ height: 1500 }}>
                        <div className='simple_form' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='card' style={{ width: '90%', height: '85%', display: 'flex', flexDirection: 'column' }}>
                                <div className='cardHeader' style={{ width: '100%', height: '15%', display: 'flex', alignItems: 'center' }}>
                                    <p style={{ fontSize: 66, letterSpacing: 5, fontFamily: 'URWGeometricBold', textShadow: '8px 8px 6px #000000', color: 'white', marginLeft: 10 }}>TU  PLAYLIST</p>
                                </div>
                                <div className='cardFoote' style={{ width: '100%', height: '85%', backgroundImage: 'linear-gradient(to right, #171029 0%, #58336a)' }}>
                                    <div className='cardImg' style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ width: '90%', height: '90%', flexDirection: 'column' }}>
                                            <div style={{ height: '10%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                                <div onClick={() => this.select(this.state.songs[0])} className={card1} style={{ backgroundColor: this.colorImage(0) }}>
                                                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                        <p style={{ color: colorText1, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[0].name}</p>
                                                    </div>
                                                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                        <p style={{ color: colorText1, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[0].author}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ height: '10%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                                <div onClick={() => this.select(this.state.songs[1])} className={card2} style={{ backgroundColor: this.colorImage(1) }}>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                        <p style={{ color: colorText2, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[1].name}</p>
                                                    </div>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                        <p style={{ color: colorText2, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[1].author}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ height: '10%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                                <div onClick={() => this.select(this.state.songs[2])} className={card3} style={{ backgroundColor: this.colorImage(2) }}>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                        <p style={{ color: colorText3, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[2].name}</p>
                                                    </div>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                        <p style={{ color: colorText3, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[2].author}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ height: '10%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                                                <div onClick={() => this.select(this.state.songs[3])} className={card4} style={{ backgroundColor: this.colorImage(3) }}>
                                                    <div style={{ height: '60%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                        <p style={{ color: colorText4, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[3].name}</p>
                                                    </div>
                                                    <div style={{ height: '60%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                        <p style={{ color: colorText4, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[3].author}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ height: '10%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                                <div onClick={() => this.select(this.state.songs[4])} className={card5} style={{ backgroundColor: this.colorImage(4) }}>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                        <p style={{ color: colorText5, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[4].name}</p>
                                                    </div>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                        <p style={{ color: colorText5, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[4].author}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ height: '10%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div onClick={() => this.select(this.state.songs[5])} className={card6} style={{ backgroundColor: this.colorImage(5) }}>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                        <p style={{ color: colorText6, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[5].name}</p>
                                                    </div>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                        <p style={{ color: colorText6, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[5].author}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ height: '10%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div onClick={() => this.select(this.state.songs[6])} className={card7} style={{ backgroundColor: this.colorImage(6) }}>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                        <p style={{ color: colorText7, fontSize: 20, fontFamily: 'URWGeometricHeavy', margin: 0 }}>{this.state.songs[6].name}</p>
                                                    </div>
                                                    <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'felx-end', margin: 0 }}>
                                                        <p style={{ color: colorText7, fontSize: 18, fontFamily: 'URWGeometricRegular', margin: 0, marginTop: 5 }}>{this.state.songs[6].author}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div style={{ width: '80%', height: '5%', display: 'flex', marginTop: 10, justifyContent: 'flex-end' }}>
                                <p style={{ color: 'white', fontSize: 16, fontFamily: 'URWGeometricMedium', margin: 0, letterSpacing: 1.5 }}>#{this.state.item.ticket}</p>
                            </div>
                        </div>

                    </div>
                </div>
            )

        }
    }


}

export default PlayGame;