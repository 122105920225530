import React from 'react';
import logo from './logo.svg';
import RequestPin from './components/RequestPin.js'
import PlayGame from './components/PlayGame.js'

import Login from './preguntados/Login.js'
import Menu from './preguntados/Menu.js'
import Questions from './preguntados/Questions.js'
import LeaderBoard from './preguntados/LeaderBoard.js'


import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:hash' exact component={Menu} />


      </Switch>
    </BrowserRouter>);
}

export default App;
