
import React from 'react';
import './style.css'
import Dialog from '../components/Dialog'
import logo from '../assets/pin_logo.png'
import pibe from '../assets/pibe.png'
import enjoy from '../assets/logo_Enjoy.png'
import konect from '../assets/logo_konecta.png'

const axios = require('axios');
//comment
// coments

class Questions extends React.Component {
    audioic = new Audio("https://preguntadospublic.s3.amazonaws.com/incorrecta.mp3");
    audioc = new Audio("https://preguntadospublic.s3.amazonaws.com/correta.mp3");

    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            dialog: false,
            fullname: "Gonzalo",
            porcentaje: 0,
            urlbase: "https://preguntadospublic.s3.amazonaws.com/",
            url: null,
            question: {},
            myResponse: null
        };
        this.Close = this.Close.bind(this)
        this.next = this.next.bind(this)

    }

    updateDimensions = () => {

        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        const data = localStorage.getItem("user");
        if (data != null) {
            var objc = JSON.parse(data);
            const name = objc.data.name;
            const dni = objc.data.dni;
            this.setState({
                fullname: name,
                dni: dni,
            }, () => {
                this.next();
            })
        }
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    Close() {
        this.setState({ dialog: false })
    }

    next() {
        var self = this;
        axios.get('https://5okzq2kxye.execute-api.us-east-1.amazonaws.com/Prod/next/?dni=' + this.state.dni)
            .then(function (response) {
                // handle success
                return response;
            })
            .catch(function (error) {
                // handle error
                self.setState({ dialog: true })
            })
            .then(function (response) {
                if (!response) {
                    document.location.href = "/Menu";
                    return
                }
                if (response && response.data) {
                    var url = self.state.urlbase
                    if (response.data.idimg != -1) {
                        url = url + response.data.idimg + ".jpg";
                    }
                    self.setState({
                        question: response.data,
                        url: url
                    })
                    self.interval = setInterval(() => {
                        self.timer();
                    }, 1000);

                } else {
                    self.setState({ dialog: true })
                }
            });
    }

    setResponse(response) {
        var self = this;
        this.stop()

        this.setState({
            myResponse: response
        })
        var data = {
            response: response,
            hash: this.state.question.hash,
            time: this.state.question.time
        }
        axios.post('https://5okzq2kxye.execute-api.us-east-1.amazonaws.com/Prod/next/?dni=' + this.state.dni, data)
            .then(function (response) {
                // handle success
                return response;
            })
            .catch(function (error) {
                // handle error
                self.setState({ dialog: true })
            })
            .then(function (response) {
                if (response && response.data) {

                    if (response.data.question.okey == self.state.myResponse) {
                        self.playCorrect();
                    } else {
                        self.playIncorrect();
                    }


                    self.setState({
                        responseQuestion: response.data
                    })
                } else {
                    self.setState({ dialog: true })
                }
                self.interval = setInterval(() => {
                    self.timerNext();
                }, 3000);

            });
    }

    playCorrect = () => {
        const audioPromise = this.audioc.play()
        if (audioPromise !== undefined) {
                audioPromise
                    .then(_ => {
                        // autoplay started
                    })
                    .catch(err => {
                        // catch dom exception
                        console.info(err)
                    })
            }
    }
    playIncorrect = () => {
        const audioPromise = this.audioic.play()
        if (audioPromise !== undefined) {
                audioPromise
                    .then(_ => {
                        // autoplay started
                    })
                    .catch(err => {
                        // catch dom exception
                        console.info(err)
                    })
            }
    }

    timer = () => {
        if (this.state.porcentaje >= 100) {
            this.stop();
            this.setResponse("-1999");
            return;
        }
        this.setState({
            porcentaje: this.state.porcentaje + 10
        })
    }

    timerNext = () => {
        this.stop();
        document.location.href = "/LeaderBoard";

    }

    stop() {
        clearInterval(this.interval);

    }
    openTerms = () => {
        window.open("https://dev2370.d2kwv40o2mk5ma.amplifyapp.com", "_blank");
    }

    render() {
        const oscuro = 'rgba(25,25,25,0.6)'
        var h = window.innerHeight;
        var p = this.state.porcentaje;
        p = p + "%"
        var pv = 10 - this.state.porcentaje/10;
        var styleopt1 = "blue-buttom";
        var styleopt2 = "blue-buttom";
        var styleopt3 = "blue-buttom";
        var styleopt4 = "blue-buttom";

        var fontcolor1 = "white";
        var fontcolor2 = "white";
        var fontcolor3 = "white";
        var fontcolor4 = "white";

        var colorR = ""
        var points = ""
        if (this.state.responseQuestion) {
            
            points = "+" + this.state.responseQuestion.points + " Puntos";
            if (this.state.responseQuestion.question.opt1 == this.state.myResponse) {
                styleopt1 = "red-buttom";
            }
            if (this.state.responseQuestion.question.opt2 == this.state.myResponse) {
                styleopt2 = "red-buttom";
            }
            if (this.state.responseQuestion.question.opt3 == this.state.myResponse) {
                styleopt3 = "red-buttom";
            }
            if (this.state.responseQuestion.question.opt4 == this.state.myResponse) {
                styleopt4 = "red-buttom";
            }
            if (this.state.responseQuestion.question.opt1 == this.state.responseQuestion.question.okey) {
                styleopt1 = "green-buttom";
            }
            if (this.state.responseQuestion.question.opt2 == this.state.responseQuestion.question.okey) {
                styleopt2 = "green-buttom";
            }
            if (this.state.responseQuestion.question.opt3 == this.state.responseQuestion.question.okey) {
                styleopt3 = "green-buttom";
            }
            if (this.state.responseQuestion.question.opt4 == this.state.responseQuestion.question.okey) {
                styleopt4 = "green-buttom";
            }
            if (this.state.responseQuestion.result == 'ok') {
                colorR = '#068c3b';
            } else {
                colorR = "#C8212C";
            }
            fontcolor1 = 'white';
            fontcolor2 = 'white';
            fontcolor3 = 'white';
            fontcolor4 = 'white';

        } else if (this.state.myResponse) {

            if (this.state.question.opt1 == this.state.myResponse) {
                styleopt1 = "white-buttom";
                fontcolor1 = 'black';
            }
            if (this.state.question.opt2 == this.state.myResponse) {
                styleopt2 = "white-buttom";
                fontcolor2 = 'black';

            }
            if (this.state.question.opt3 == this.state.myResponse) {
                styleopt3 = "white-buttom";
                fontcolor3 = 'black';
            }
            if (this.state.question.opt4 == this.state.myResponse) {
                styleopt4 = "white-buttom";
                fontcolor4 = 'black';

            }


        }
        if (window.innerWidth < 850) {
            return (
                <div className='bodycResponsivePreguntados' style={{minHeight: h, overflowY: "scroll", width: window.innerWidth }}>
                    <div style={{ minHeight: h, overflowY: "scroll", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 0.5 }}>
                                <span style={{ fontFamily: 'Gotham Black', color: '#FFFFFF', fontSize: 16, marginLeft: 10, marginTop: 2 }}>Pregunta {this.state.question.current_questions}/10</span>
                            </div>
                            <div style={{ textAlign: 'right', flex: 0.5, alignItems: 'flex-end', alignSelf: 'flex-end', marginRight: 10 }}>
                                <span style={{ fontFamily: 'Gotham Black', color: '#FFFFFF', fontSize: 16, marginLeft: 10, marginTop: 2 }}>{this.state.question.points} pts</span>
                            </div>
                        </div>
                        <div style={{ flexDirection: 'column', color: 'white', width: '100%', display: 'flex', alignContent: 'center' }}>
                            <div style={{ width: '100%' }}>
                                 <p style={{textAlign: 'center', color: '#044592',fontFamily: 'Gotham Black'}}>{pv} seg.</p>
                                <div style={{ marginLeft: '10%', marginTop: 20, borderRadius: 40, width: '80%', background: 'white' }}>
                                    <div style={{ borderRadius: 40, height: 10, width: p, background: '#366CA6' }}></div>
                                </div>
                                <p style={{ fontFamily: 'Gotham Black', width: '100%', color: '#044592', textAlign: 'center', fontSize: 20 }}>
                                    {this.state.question.genero}
                                </p>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <p style={{ color: '#044592', fontFamily: 'Gotham Black', width: '100%', textAlign: 'center', fontSize: 20 }}>
                                        {this.state.question.question}<br/>
                                        {this.state.question.multiply}
                                    </p>
                                </div>
                            </div>
                            {this.state.question.idimg != -1 &&
                                <div>
                                    <img src={this.state.url} style={{ borderRadius: 10, marginLeft: '35%', width: '30%', backgroundColor: 'transparent' }} />
                                </div>
                            }

                        </div>
                        {(this.state.responseQuestion || this.state.myResponse) &&

                            <div style={{ flex: 1, width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: 'flex', marginTop: 10, flexDirection: 'column', width: '100%' }}>
                                    <div className={styleopt1} style={{ borderWidth: 3, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%' }}>
                                        <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: fontcolor1}}>{this.state.question.opt1}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: 10, flexDirection: 'column', width: '100%' }}>
                                    <div className={styleopt2} style={{ borderWidth: 3, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%' }}>
                                        <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: fontcolor2 }}>{this.state.question.opt2}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: 10, flexDirection: 'column', width: '100%' }}>
                                    <div className={styleopt3} style={{ borderWidth: 3, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%' }}>
                                        <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: fontcolor3 }}>{this.state.question.opt3}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: 10, flexDirection: 'column', width: '100%' }}>
                                    <div className={styleopt4} style={{ borderWidth: 3, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%' }}>
                                        <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: fontcolor4}}>{this.state.question.opt4}</p>
                                    </div>
                                </div>
                                <p className='name'  style={{ color: colorR, textAlign: 'center', fontWeight: 'bold', fontFamily: 'Gotham Black', fontSize: 20 }}>
                                    {points}
                                </p>
                            </div>

                        }

                        {(!this.state.responseQuestion && !this.state.myResponse) &&

                            <div style={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                <div onClick={() => this.setResponse(this.state.question.opt1)} style={{ cursor: 'pointer', display: 'flex', marginTop: 10, flexDirection: 'column', width: '100%' }}>
                                    <div className='blue-buttom' style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%' }}>
                                        <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: 'white' }}>{this.state.question.opt1}</p>
                                    </div>
                                </div>
                                <div onClick={() => this.setResponse(this.state.question.opt2)} style={{ cursor: 'pointer', display: 'flex', marginTop: 10, flexDirection: 'column', width: '100%' }}>
                                    <div className='blue-buttom' style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%', }}>
                                        <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: 'white' }}>{this.state.question.opt2}</p>
                                    </div>
                                </div>
                                <div onClick={() => this.setResponse(this.state.question.opt3)} style={{ cursor: 'pointer', display: 'flex', marginTop: 10, flexDirection: 'column', width: '100%' }}>
                                    <div className='blue-buttom' style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%', }}>
                                        <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: 'white' }}>{this.state.question.opt3}</p>
                                    </div>
                                </div>
                                <div onClick={() => this.setResponse(this.state.question.opt4)} style={{ cursor: 'pointer', display: 'flex', marginTop: 10, flexDirection: 'column', width: '100%' }}>
                                    <div className='blue-buttom' style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%', }}>
                                        <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: 'white' }}>{this.state.question.opt4}</p>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div className='bodycPreguntados' style={{ height: window.innerHeight, overflowY: "scroll", width: window.innerWidth }}>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 0.5 }}>
                                <span style={{ fontFamily: 'Gotham Black', color: '#FFFFFF', fontSize: 16, marginLeft: 10, marginTop: 2 }}>Pregunta {this.state.question.current_questions}/10</span>
                            </div>
                            <div style={{ textAlign: 'right', flex: 0.5, alignItems: 'flex-end', alignSelf: 'flex-end', marginRight: 10 }}>
                                <span style={{ fontFamily: 'Gotham Black', color: '#FFFFFF', fontSize: 16, marginLeft: 10, marginTop: 2 }}>{this.state.question.points} pts</span>
                            </div>
                        </div>
                        <div style={{ flexDirection: 'column', color: 'white', width: '100%', display: 'flex', alignContent: 'center' }}>
                            <div style={{marginLeft: '25%', width: '50%', marginBottom: 10 }}>
                                <p style={{textAlign: 'center', color: '#044592',fontFamily: 'Gotham Black'}}>{pv} seg.</p>
                                <div style={{ marginLeft: '10%', marginTop: 20, borderRadius: 40, width: '80%', background: 'white' }}>
                                    <div style={{  borderRadius: 40, height: 10, width: p, background: '#366CA6' }}></div>
                                </div>
                                <p style={{ fontFamily: 'Gotham Black', width: '100%', color: '#044592', textAlign: 'center', fontSize: 26 }}>
                                    {this.state.question.genero}
                                </p>
                                <div className='blue-buttom' style={{padding: 2, width: '100%', textAlign: 'center', borderWidth: 3, borderColor: '#ffffff', borderStyle: 'solid', borderRadius: 13 }}>
                                    <p style={{ color: 'white', fontFamily: 'Gotham Black', width: '100%', textAlign: 'center', fontSize: 22 }}>
                                        {this.state.question.question}<br/>
                                        {this.state.question.multiply}

                                    </p>
                                </div>

                            </div>
                            {this.state.question.idimg != -1 &&
                                <div>
                                    <img src={this.state.url} style={{ borderRadius: 10, marginLeft: '45%', width: '10%', backgroundColor: 'transparent' }} />
                                </div>
                            }
                        </div>
                        {(this.state.responseQuestion || this.state.myResponse) &&

                            <div style={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                <div style={{ flex: 'row' }}>
                                    <div style={{ flex: 0.5, cursor: 'pointer', display: 'flex', marginTop: 10, flexDirection: 'row', width: '100%' }}>
                                        <div className={styleopt1} onClick={() => this.setResponse(this.state.question.opt1)}  style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', width: '20%', marginLeft: '25%', marginRight: '5%' }}>
                                            <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: fontcolor1 }}>{this.state.question.opt1}</p>
                                        </div>
                                        <div className={styleopt2} onClick={() => this.setResponse(this.state.question.opt2)}  style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', width: '20%', marginLeft: '5%', marginRight: '15%', }}>
                                            <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: fontcolor2 }}>{this.state.question.opt2}</p>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ flex: 'row' }}>
                                    <div style={{flex: 0.5, cursor: 'pointer', display: 'flex', marginTop: 10, flexDirection: 'row', width: '100%' }}>
                                        <div className={styleopt3} onClick={() => this.setResponse(this.state.question.opt3)} style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid',width: '20%', marginLeft: '25%', marginRight: '5%', }}>
                                            <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: fontcolor3}}>{this.state.question.opt3}</p>
                                        </div>
                                        <div className={styleopt4} onClick={() => this.setResponse(this.state.question.opt4)}  style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid',width: '20%', marginLeft: '5%', marginRight: '15%', }}>
                                            <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: fontcolor4 }}>{this.state.question.opt4}</p>
                                        </div>
                                    </div>
                                </div>
                                <p className='name'  style={{ color: colorR,fontWeight: 'bold', textAlign: 'center', fontFamily: 'Gotham Black', fontSize: 20 }}>
                                    {points}
                                </p>
                            </div>                            
                        }
                        {(!this.state.responseQuestion && !this.state.myResponse) &&
                            <div style={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                <div style={{ flex: 'row' }}>
                                    <div style={{ flex: 0.5, cursor: 'pointer', display: 'flex', marginTop: 10, flexDirection: 'row', width: '100%' }}>
                                        <div onClick={() => this.setResponse(this.state.question.opt1)} className='blue-buttom' style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', width: '20%', marginLeft: '25%', marginRight: '5%' }}>
                                            <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: 'white' }}>{this.state.question.opt1}</p>
                                        </div>
                                        <div onClick={() => this.setResponse(this.state.question.opt2)} className='blue-buttom' style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', width: '20%', marginLeft: '5%', marginRight: '15%', }}>
                                            <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: 'white' }}>{this.state.question.opt2}</p>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ flex: 'row' }}>
                                    <div style={{flex: 0.5, cursor: 'pointer', display: 'flex', marginTop: 10, flexDirection: 'row', width: '100%' }}>
                                        <div onClick={() => this.setResponse(this.state.question.opt3)} className='blue-buttom' style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid',width: '20%', marginLeft: '25%', marginRight: '5%', }}>
                                            <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: 'white' }}>{this.state.question.opt3}</p>
                                        </div>
                                        <div onClick={() => this.setResponse(this.state.question.opt4)} className='blue-buttom' style={{ borderWidth: 2, textAlign: 'center', fontSize: 20, borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid',width: '20%', marginLeft: '5%', marginRight: '15%', }}>
                                            <p style={{ fontFamily: 'Gotham Black', fontSize: 22, textAlign: 'center', justifyContent: 'center', color: 'white' }}>{this.state.question.opt4}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{position: 'fixed',  height: 30, bottom: 0, left: 100, right: 0}}>
                        <img src={konect} style={{  width: 96, height: 17 }} />
                    </div>
                    <div style={{position: 'fixed',  height: 30, bottom: 0, right: 100}}>
                        <img src={enjoy} style={{  width: 96, height: 24 }} />
                    </div>

                </div>
            );
        }

    }


}

export default Questions;