
import React from 'react';
import './style.css'
import Dialog from '../components/Dialog'
import logo from '../assets/pin_logo.png'
import pibe from '../assets/pibe.png'
import enjoy from '../assets/logo_Enjoy.png'
import konect from '../assets/logo_konecta.png'

const axios = require('axios');
//comment
// coments

class LeaderBoard extends React.Component {

    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            dialog: false,
            fullname: "Gonzalo",
            city: "",
            data: [],
            user: {},
            current_questions: 0,
        };
        this.Close = this.Close.bind(this)
        this.getLeaderBoard = this.getLeaderBoard.bind(this)

    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {

        const data = localStorage.getItem("user");
        if (data != null) {
            var objc = JSON.parse(data);
            const name = objc.data.name;
            const dni = objc.data.dni;
            const city = objc.data.city;
            this.setState({
                fullname: name,
                dni: dni,
                city: city
            }, () => {
                this.getLeaderBoard();
            })
        }
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    Close() {
        this.setState({ dialog: false })
    }

    getLeaderBoard() {
        var self = this;
        var url = 'https://5okzq2kxye.execute-api.us-east-1.amazonaws.com/Prod/leaderboard?dni=' + this.state.dni;
        axios.get(url)
            .then(function (response) {
                // handle success
                return response;
            })
            .catch(function (error) {
                // handle error
                self.setState({ dialog: true })
            })
            .then(function (response) {
                if (response && response.data) {
                    self.setState({
                        data: response.data.data,
                        user: response.data.user,
                        current_questions : response.data.current_questions
                    })
                } else {
                    self.setState({ dialog: true })
                }
            });
    }
    openTerms = () => {
        window.open("https://dev2370.d2kwv40o2mk5ma.amplifyapp.com", "_blank");
    }

    continue = () => {
        if (this.props.location.search.indexOf("menu") > -1) {
            document.location.href = "/Menu";
        } else {
            document.location.href = "/Questions";
        }
    }

    renderItem = (i,item) => {
        var background = '#181A1D';
        if (i > 0) {
            background = "#585F67";
        }
        if (item['isme']) {
            background = "#1c539b";
        }
        var score = item.score;
        return (
            <div style={{ display: 'flex', height: 80, margin: 10, marginBottom: 5, flexDirection: 'row',  borderRadius: 10, background: background, width: '90%', marginLeft: '5%', marginRight: '5%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <div style={{ flex: 0.2 }}>
                    <p style={{ fontFamily: 'Gotham Black', fontSize: 18, textAlign: 'center', justifyContent: 'center', color: 'white' }} >{item.position}</p>
                </div>
                <div style={{ fontFamily: 'Gotham Black', fontSize: 18, width: 2, height: '100%', background: 'white' }}>
                </div>
                <div style={{ flex: 0.55 }}>
                    <p style={{ fontFamily: 'Gotham Black', fontSize: 18, marginLeft: 10, justifyContent: 'center', color: 'white' }} >{item.name}</p>
                </div>
                <div style={{ flex: 0.25 }}>
                    <p style={{ fontFamily: 'Gotham Black', fontSize: 18, textAlign: 'center', justifyContent: 'center', color: 'white' }} >{new Intl.NumberFormat("es-ES").format(score)} pts</p>
                </div>

            </div>

        )
    }

    render() {
        const oscuro = 'rgba(25,25,25,0.6)'
        const h = window.innerHeight;
        console.log(window.innerWidth)

        if (window.innerWidth < 850) {
            return (
                <div className='bodycResponsivePreguntados' style={{minHeight: h, width: window.innerWidth }}>
                    <div style={{flex: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: 50, width: '100%', display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 0.5 }}>
                                <span style={{ fontFamily: 'Gotham Black', color: '#FFFFFF', fontSize: 16, marginLeft: 10, marginTop: 2 }}>Preguntados {this.state.current_questions}/10</span>
                            </div>
                            <div style={{ textAlign: 'right', flex: 0.5, alignItems: 'flex-end', alignSelf: 'flex-end', marginRight: 10 }}>
                                <span style={{ fontFamily: 'Gotham Black', color: '#FFFFFF', fontSize: 16, marginLeft: 10, marginTop: 2 }}>{this.state.user.city}</span>
                            </div>
                        </div>

                        {this.state.data.map((item,index) => {
                            return this.renderItem(index,item);
                        })}

                        <div onClick={() => this.continue()} className='blue-buttom' style={{ cursor: 'pointer', borderWidth: 3, marginTop: 40, textAlign: 'center', borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '20%', marginRight: '20%', width: '60%' }}>
                            <p style={{ fontFamily: 'Gotham Black', textAlign: 'center', color: 'white' }}>CONTINUAR</p>
                        </div>

                    </div>
                </div>
            );
        } else {
            return (
                <div className='bodycPreguntados' style={{minHeight: h,  overflowY: "scroll", width: window.innerWidth }}>
                    <div style={{ marginBottom: 15, width: '100%', display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 0.5 }}>
                            <span style={{ fontFamily: 'Gotham Black', color: '#FFFFFF', fontSize: 16, marginLeft: 10, marginTop: 2 }}>Preguntados {this.state.current_questions}/10</span>
                        </div>
                        <div style={{ textAlign: 'right', flex: 0.5, alignItems: 'flex-end', alignSelf: 'flex-end', marginRight: 10 }}>
                            <span style={{ fontFamily: 'Gotham Black', color: '#FFFFFF', fontSize: 16, marginLeft: 10, marginTop: 2 }}>{this.state.user.city}</span>
                        </div>
                    </div>
                    <div style={{ marginLeft: '30%', width: '40%', height: '100%', display: 'flex', flexDirection: 'column' }}>

                        {this.state.data.map((item,index) => {
                            return this.renderItem(index,item);
                        })}

                        <div onClick={() => this.continue()} className='blue-buttom' style={{ cursor: 'pointer', borderWidth: 3, marginTop: 10, textAlign: 'center', borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '20%', marginRight: '20%', width: '60%' }}>
                            <p  style={{ fontFamily: 'Gotham Black', textAlign: 'center', color: 'white', height: '100%' }}>CONTINUAR</p>
                        </div>

                    </div>
                    <div style={{ position: 'fixed',  height: 30, bottom: 0, left: 100}}>
                        <img src={konect} style={{  width: 96, height: 17 }} />
                    </div>
                    <div style={{position: 'fixed',  height: 30, bottom: 0, right: 100}}>
                        <img src={enjoy} style={{  width: 96, height: 24 }} />
                    </div>

                </div>
            );
        }

    }


}

export default LeaderBoard;