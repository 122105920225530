
import React from 'react';
import './style.css'
import Dialog from '../components/Dialog'
import logo from '../assets/logo.png'
import pibe from '../assets/pibe.png'
import enjoy from '../assets/logo_Enjoy.png'
import konect from '../assets/logo_konecta.png'

const axios = require('axios');
//comment
// coments

class Menu extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            dialog: false,
            fullname: "Gonzalo",
            dni: '23333333',
            response: {"video": "", "name": ""}

        };
        this.Close = this.Close.bind(this)
        this.validate = this.validate.bind(this)
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentDidMount() {
        let urlElements = window.location.href.split('/')

        this.setState({
            hash: urlElements[urlElements.length-1]
        }, () => {
            this.validate();
        })
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    Close() {
        this.setState({ dialog: false })
    }

    validate() {
        var self = this;
        axios.get(
            'https://jp3bsr5xbg.execute-api.us-east-1.amazonaws.com/default/videos?pin=' + this.state.hash,
            {
                "headers" : {
                    'Content-Type': 'application/json'
                } 
            })
            .then(function (response) {
                // handle success
                return response;
            })
            .catch(function (error) {
                // handle error
                self.setState({ dialog: true })
            })
            .then(function (response) {
                if (response && response.data) {
                    self.setState({
                        response: response.data
                    })
                } else {
                    self.setState({ dialog: true })
                }
            });
    }
    openTerms = () => {
        window.open("https://dev2370.d2kwv40o2mk5ma.amplifyapp.com", "_blank");
    }
    
    goToNext = () => {
        document.location.href = "/Questions";
    }
    getBlock = (type) => {

        var wich = 10
        if (type == 1) {
            if (this.state.response.data.day1 == 'CURRENT') {
                wich = 0
            }
            if (this.state.response.data.day1 == 'COMPLETE') {
                wich = 20
            }
        }
        if (type == 2) {
            if (this.state.response.data.day2 == 'CURRENT') {
                wich = 0
            }
            if (this.state.response.data.day2 == 'COMPLETE') {
                wich = 20
            }
        }
        if (type == 3) {
            if (this.state.response.data.day3 == 'CURRENT') {
                wich = 0
            }
            if (this.state.response.data.day3 == 'COMPLETE') {
                wich = 20
            }
        }
        if (type == 4) {
            if (this.state.response.data.day4 == 'CURRENT') {
                wich = 0
            }
            if (this.state.response.data.day4 == 'COMPLETE') {
                wich = 20
            }
        }
        if (type == 5) {
            if (this.state.response.data.day5 == 'CURRENT') {
                wich = 0
            }
            if (this.state.response.data.day5 == 'COMPLETE') {
                wich = 20
            }
        }
        if (type == 6) {
            if (this.state.response.data.day6 == 'CURRENT') {
                wich = 0
            }
            if (this.state.response.data.day6 == 'COMPLETE') {
                wich = 20
            }
        }

        if (wich == 0) {
            return (
                <div onClick={() => this.goToNext()} className='blue-buttom' style={{ cursor: 'pointer', borderWidth:4, textAlign: 'center', borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '5%', marginRight: '5%',  width: '40%'}}>
                    <p style={{fontFamily: 'Gotham Black', textAlign: 'center', marginBottom: 0, marginTop: 10}} >JUGAR</p>
                    <p style={{fontFamily: 'Gotham Black', textAlign: 'center'}} >TRIVIA</p>
                    <p style={{fontFamily: 'Gotham Black', textAlign: 'center', marginBottom: 10}} >{this.state.response.daysLabel[type-1]}</p>
            </div>
    
            )
        }
        if (wich == 10) {
            return (
                <div className='blue-buttom-l' style={{ opacity:0.8, borderWidth:3, textAlign: 'center', borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '5%', marginRight: '5%',  width: '40%'}}>
                    <p style={{fontFamily: 'Gotham Black', textAlign: 'center', color: 'gray', marginBottom: 0, marginTop: 10}} >JUGAR</p>
                    <p style={{fontFamily: 'Gotham Black', textAlign: 'center',color: 'gray'}} >TRIVIA</p>
                    <p style={{fontFamily: 'Gotham Black', textAlign: 'center',color: 'gray', marginBottom: 10}} >{this.state.response.daysLabel[type-1]}</p>
                </div>

            )
        }
        if (wich == 20) {
            return (
                <div className='green-buttom-l' style={{ borderWidth:3, textAlign: 'center', borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '5%', marginRight: '5%',  width: '40%'}}>
                    <p style={{fontFamily: 'Gotham Black', textAlign: 'center', color: 'white', marginBottom: 0, marginTop: 10}} >TRIVIA</p>
                    <p style={{fontFamily: 'Gotham Black', textAlign: 'center',color: 'white'}} >{this.state.response.daysLabel[type-1]}</p>
                    <p style={{fontFamily: 'Gotham Black', textAlign: 'center',color: 'white', marginBottom: 10}} >FINALIZADO</p>
                </div>

            )
        }
    }

    loaderBoard = () => {
        document.location.href = "/LeaderBoard?goto=menu";
    }
    render() {
        const oscuro = 'rgba(25,25,25,0.6)'
        const h = window.innerHeight;
        var count = 0
        console.log(this.state.response);
        if (true || window.innerWidth < 850) {
            return (
                <div className='bodycResponsivePreguntados' style={{ width: '100%', height: '100%'}}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{overflowY: "scroll", color: 'white', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='title' style={{ width: '100%',  display: 'flex', flexDirection: 'column' }}>
                                <img src={logo} style={{marginTop: 30   ,  height: 200*0.95, backgroundColor: 'transparent', textAlign: 'center' }} />
                                <p style={{marginBottom: 50, fontFamily: 'Gotham Black', color: 'white', marginBottom: 0, marginLeft: 30, marginRight: 30}}>
                                    {this.state.response.name}
                                </p>
                                {this.state.response && this.state.response.video.startsWith("https://pre") ?
                                    <p style={{marginTop: 50}}>
                                        <img src={this.state.response.video} />
                                    </p>
                                :

                                    <p style={{marginTop: 50}}>
                                        <iframe width={window.innerWidth*0.9} height={window.innerWidth*0.6} src={this.state.response.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
       
        }

    }


}

export default Menu;