
import React from 'react';
import './style.css'
import Dialog from '../components/Dialog'
import logo from '../assets/logo.svg'
import pibe from '../assets/pibe.png'
import enjoy from '../assets/logo_Enjoy.png'
import konect from '../assets/logo_konecta.png'

const axios = require('axios');
//comment
// coments
class Login extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            dialog: false,
        };
        this.Close = this.Close.bind(this)
        this.validate = this.validate.bind(this)

    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    Close() {
        this.setState({ dialog: false })
    }

    validate() {
        var self = this;
        //https://z6f1fnfh97.execute-api.us-east-1.amazonaws.com/V1/getpin?pin=BC4610951920
        //'https://api.gameandmusic.com.ar:3001/getPIN?pin='
        axios.get('https://5okzq2kxye.execute-api.us-east-1.amazonaws.com/Prod/check-pin/?dni=' + this.state.text)
            .then(function (response) {
                // handle success
                return response;
            })
            .catch(function (error) {
                // handle error
                self.setState({ dialog: true })
            })
            .then(function (response) {
                if (response && response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                    document.location.href = "/Menu";
                } else {
                    self.setState({ dialog: true })
                }
            });
    }
    openTerms = () => {
        window.open("https://preguntadospublic.s3.amazonaws.com/BASES+Y+CONDICIONES+PREGUNTADOS+.pdf", "_blank");
    }
    render() {
        const oscuro = 'rgba(25,25,25,0.6)'
        const h = window.innerHeight;
        console.log(window.innerWidth)

        if (window.innerWidth < 850) {
            return (
                <div className='bodycResponsive' style={{ height: h, width: window.innerWidth }}>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='title' style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <img src={logo} style={{ zIndex: 9999, marginTop: 10, position: 'aboslute', top: 20, left: 0, width: 200, height: 200 * 0.95, backgroundColor: 'transparent', textAlign: 'center' }} />

                                <div className='title' style={{ borderRadius: 20, marginLeft: '10%', marginRight: '10%', paddingTop: 70, backgroundColor: 'rgb(156,213,252)', marginTop: -80, width: '80%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <p className='titleLabel' style={{ padding: 20 }}>INGRESÁ TU DNI Y EMPEZÁ A JUGAR.</p>
                                    <div style={{ width: '70%', height: 35, borderRadius: 20, borderStyle: 'solid', borderColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 0 }}>
                                        <input
                                            placeholder={'Ej. 35293992'}
                                            type={'text'}
                                            onChange={(e) => this.setState({ text: e.target.value })}
                                            style={{ backgroundColor: oscuro, width: '100%', height: '100%', borderStyle: 'none', color: 'white', fontFamily: 'Gotham Black', fontSize: 16, paddingLeft: 10, paddingRight: 10, borderRadius: 24 }}>
                                        </input>
                                    </div>
                                    {/* <a className='buttonPlay'><span className='buttonLabel'>LET´S PLAY</span></a> */}

                                    <div onClick={() => this.validate()} className='blue-buttom' style={{ cursor: 'pointer', borderWidth: 3, marginTop: 40, textAlign: 'center', borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%', width: '60%' }}>
                                        <p style={{ fontFamily: 'Gotham Black', textAlign: 'center', color: 'white' }}>CONFIRMAR</p>
                                    </div>
                                    <div style={{ marginTop: 10 }} />
                                    <p className='titleLabel' style={{ padding: 5 }}>CONOCÉ COMO PARTICIPAR</p>
                                    <p onClick={() =>this.openTerms()} className='titleLabel' style={{cursor: 'pointer', paddingBottom: 20}}>HACÉ CLICK AQUÍ.</p>

                                </div>
                            </div>
                        </div>
                        <div style={{position: 'fixed',  height: 30, bottom: 0, left: 5, right: 0}}>
                            <img src={konect} style={{  width: 96, height: 17 }} />
                        </div>
                        <div style={{position: 'fixed',  height: 30, bottom: 0, right: 5}}>
                            <img src={enjoy} style={{  width: 96, height: 24 }} />
                        </div>
                    </div>
                    <Dialog dialog={this.state.dialog} onClose={() => { this.Close() }}></Dialog>
                </div>
            );
        } else {
            
            return (
                <div className='bodycPreguntados' style={{ height: h, width: window.innerWidth }}>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        </div>
                        <div style={{marginLeft: '20%', width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='title' style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <img src={logo} style={{ zIndex: 9999, marginTop: 10, position: 'aboslute', top: 20, left: 0, width: 250, height: 250 * 0.95, backgroundColor: 'transparent', textAlign: 'center' }} />

                                <div className='title' style={{ borderRadius: 20, marginLeft: '25%', marginRight: '25%', paddingTop: 100, backgroundColor: 'rgb(156,213,252)', marginTop: -80, width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <p className='titleLabel' style={{ padding: 20 }}>INGRESÁ TU DNI Y EMPEZÁ A JUGAR.</p>
                                    <div style={{ width: '70%', height: 35, borderRadius: 20, borderStyle: 'solid', borderColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 0 }}>
                                        <input
                                            placeholder={'Ej. 35293992'}
                                            type={'text'}
                                            onChange={(e) => this.setState({ text: e.target.value })}
                                            style={{ backgroundColor: oscuro, width: '100%', height: '100%', borderStyle: 'none', color: 'white', fontFamily: 'Gotham Black', fontSize: 16, paddingLeft: 10, paddingRight: 10, borderRadius: 24 }}>
                                        </input>
                                    </div>
                                    {/* <a className='buttonPlay'><span className='buttonLabel'>LET´S PLAY</span></a> */}

                                    <div onClick={() => this.validate()} className='blue-buttom' style={{ cursor: 'pointer', borderWidth: 3, marginTop: 40, textAlign: 'center', borderRadius: 20, borderColor: '#ffffff', borderStyle: 'solid', marginLeft: '15%', marginRight: '15%', width: '60%' }}>
                                        <p style={{ fontFamily: 'Gotham Black', textAlign: 'center', color: 'white' }}>CONFIRMAR</p>
                                    </div>
                                    <div style={{ marginTop: 10 }} />
                                    <p className='titleLabel' style={{ padding: 5 }}>CONOCÉ COMO PARTICIPAR</p>
                                    <p onClick={() =>this.openTerms()} className='titleLabel' style={{cursor: 'pointer', paddingBottom: 20}}>HACÉ CLICK AQUÍ.</p>

                                </div>
                            </div>
                        </div>
                        <div style={{position: 'fixed',  height: 30, bottom: 0, left: 100, right: 0}}>
                            <img src={konect} style={{  width: 96, height: 17 }} />
                        </div>
                        <div style={{position: 'fixed',  height: 30, bottom: 0, right: 100}}>
                        <   img src={enjoy} style={{  width: 96, height: 24 }} />
                        </div>
                    </div>
                    <Dialog dialog={this.state.dialog} onClose={() => { this.Close() }}></Dialog>
                </div>
            );        }

    }


}

export default Login;