
import React from 'react';
import './style.css'
import Primavera from '../assets/primavera.png'
import cancel from '../assets/cancel.png'
class Dialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {width: props.height};
      }
    
      componentWillMount(){
        this.setState({width: window.innerWidth});
      }

    render() {
        const oscuro = 'rgba(25,25,25,0.6)'
        const h = window.innerHeight;
        const w = this.state.width
        if(this.props.dialog){
            return (
                <div style={{height:'100%', width:'100%',position:'absolute',top:0,left:0,backgroundColor:oscuro,display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{ width: w > 850 ? '30%' : '80%',height: h > 850 ? '30%' : '40%',backgroundColor:'white',borderRadius:20,display:'flex',flexDirection:'column',alignItems:'center',position:'relative',boxShadow:'5px 5px 10px 0 #000000'}}>
                        <p className="dialog" style={{ margin:0,marginTop:25}}>X</p>
                        <p style={{ margin:0,marginTop:10,color:'#310a46',fontSize:20,fontFamily:'RobotoBold',letterSpacing:1.2,textAlign:"center"}}>DNI INCORRECTO</p>
                        <p style={{ margin:0,marginTop:20,color:'balck',fontSize:16,fontFamily:'RobotoRegular',letterSpacing:1.2,width:'80%',textAlign:'center',fontWeight:'bold'}}>Debes ingregar tu DNI.</p>
                        <div onClick={() => this.props.onClose()} style={{cursor:'pointer',width:28,height:28,borderRadius:14,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#e4431d',position:'absolute',top:-14,right:-14,}}>
                            <img src={cancel} style={{width:67*0.2,height:67*0.2}}></img>
                        </div>
                    </div>
                </div>
    
            );
        }else{
            return(<div></div>)
        }
        
    }


}

export default Dialog;